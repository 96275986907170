import { crmAPI } from '../api/crmAPI';

//SweetAlert
import Swal from 'sweetalert2';

//User Slice
import { setUser, setLoading, setError } from './reducers/userSlice';

//Business Slice
import { setBusiness, setBusinessLoading, setBusinessError } from './reducers/businessSlice';

/*********************** User Redux *************************/
export const registerUser = (email, password) => async (dispatch) => {
    try {
        dispatch(setLoading(true)); // Set loading state to true
        
        crmAPI.register(email, password).then((response) => {

            //Check to see if there are any Hubs
            if (response.status === 201) {
               
                dispatch(setUser(response.data.user)); // Dispatch action to set current user

                //Store the token in the local storage
                localStorage.setItem('RAToken', response.data.tokens.refresh.token);
            }
        }).catch((error) => {

            Swal.fire({
                icon: 'error',
                title: 'Login error',
                text: error.response.data.message,
            });
            //console.log(error.response.data.message);
        });
    } catch (error) {
      dispatch(setError(error.message)); // Dispatch action to store error
    } finally {
      dispatch(setLoading(false)); // Set loading state to false (optional)
    }
};

/**
 * Login User
 * @param {*} email 
 * @param {*} password 
 * @returns 
 */
export const loginUser = (email, password) => async (dispatch) => {
    try {
        dispatch(setLoading(true)); // Set loading state to true

        crmAPI.login(email, password).then((response) => {

            //Check to see if there are any Hubs
            if (response.status === 200) {
                    
                dispatch(setUser(response.data.user)); // Dispatch action to set current user
            
                //Store the token in the local storage
                localStorage.setItem('RAToken', response.data.tokens.refresh.token);
            }
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Login error',
                text: error.response.data.message,
            });
            //console.log(error.response.data.message);
        });
    } catch (error) {
        dispatch(setError(error.message)); // Dispatch action to store error
    } finally {
            dispatch(setLoading(false)); // Set loading state to false (optional)
    }
};
  
/**
 * Check Browser Storage to auto login user
 * @param {*} token 
 * @returns 
 */
export const tokenLoginUser = (token) => async (dispatch) => {
    try {
        dispatch(setLoading(true)); // Set loading state to true

        crmAPI.checklogintoken(token).then((response) => {
            
            //Check to see if there are any Hubs
            if (response.status === 200) {
                    
                const user = response.data.user;

                if (user._id !== undefined) {
                    user.id = user._id;
                }
                else if (user.id !== undefined) {
                    user._id = user.id;
                }
                
                dispatch(setUser(response.data.user)); // Dispatch action to set current user
            }
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Login error',
                text: error.response.data.message,
            });
            //console.log(error.response.data.message);
        });
    } catch (error) {
        dispatch(setError(error.message)); // Dispatch action to store error
    } finally {
            dispatch(setLoading(false)); // Set loading state to false (optional)
    }
};

/**
 * This function obtains the user's data through a Google login
 * @param {*} email
 */
export const googleLoginUser = (email) => async (dispatch) => {

    try {
        dispatch(setLoading(true)); // Set loading state to true

        crmAPI.checkGoogleLogin(email).then((response) => {

            //Check to see if there are any Hubs
            if (response.status === 200) {
                    
                dispatch(setUser(response.data.user)); // Dispatch action to set current user
            
                //Store the token in the local storage
                localStorage.setItem('RAToken', response.data.tokens.refresh.token);
            }
        }).catch((error) => {    
            //console.log(error.response.data.error);
            //console.log(error.response.data.message);

            Swal.fire({
                icon: 'error',
                title: 'Login error',
                text: error.response.data.error,
            });
        });
    }
    catch (error) {
        dispatch(setError(error.response.data.error)); // Dispatch action to store error
    }
    finally {
        dispatch(setLoading(false)); // Set loading state to false (optional)
    }
};


/********************************* Update User *********************************/
/**
 * Update User
 * @param {*} id
 * @param {*} name
 * @param {*} email
 * @param {*} password
 * @param {*} image
 * @param {*} city
 * @param {*} state
 * @param {*} country
 * @param {*} latitude
 * @param {*} longitude
 * @returns
 **/
export const updateUser = (id, name, email, city, state, country, latitude, longitude, old_password, new_password, image) => (dispatch) => {

    try {
        dispatch(setLoading(true)); // Set loading state to true

        crmAPI.updateUser(id, name, email, city, state, country, latitude, longitude, old_password, new_password, image).then((response) => {
                
                //Check to see if there are any Hubs
                if (response.status === 200) {
                    
                    dispatch(setUser(response.data.user)); // Dispatch action to set current user

                    Swal.fire({
                        title: 'Success',
                        text: 'User updated Successfully',
                        icon: 'success',
                    });
                }
            }).catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Update User error',
                    text: error.response.data.message,
                });
                //console.log(error.response.data.message);
            }
        );
    }
    catch (error) {
        dispatch(setError(error.message)); // Dispatch action to store error
    }
    finally {
        dispatch(setLoading(false)); // Set loading state to false (optional)
    }
};

