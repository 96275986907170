import { api } from "./config/axiosConfig"

export const crmAPI = {
  login: async function (email, password) {

    const response = await api.request({
      url: `/auth/login`,
      method: "POST",
      data: {
        email: email,
        password: password,
      },
    })
    
    // returning the product returned by the API
    return response
  },

  register: async function (email, password) {

    const response = await api.request({
      url: `/auth/register`,
      method: "POST",
      data: {
        email: email,
        password: password,
      },
    })
    
    // returning the product returned by the API
    return response
  },

  // Persistent Login - checking user token
  checklogintoken: async function (token) {

    const response = await api.request({
      url: `/auth/check-token`,
      method: "POST",
      data: {
        token: token,
      },
    })
    
    // returning the product returned by the API
    return response
  },

  // User Login - via Google
  checkGoogleLogin: async function (email) {

    const response = await api.request({
      url: `/auth/check-login`,
      method: "POST",
      data: {
        email: email,
      },
    })
    
    // returning the product returned by the API
    return response
  },

  resetPassword: async function (token, password) {

    const response = await api.request({
      url: `/auth/reset-password`,
      method: "POST",
      params: {
        token: token,
      },
      data: {
        password: password,
      },
    })

    // returning the product returned by the API
    return response
  },

  forgotPassword: async function (email) {

    const response = await api.request({
      url: `/auth/forgot-password`,
      method: "POST",
      data: {
        email: email,
      },
    })

    // returning the product returned by the API
    return response
  },

  /****************************** Business *********************************/
  createBusiness: async function (userId, name, image, address, city, province, country, website, bookingLink, duration, message) {

    const formData = new FormData();
    formData.append('userId', userId);
    formData.append('name', name);
    formData.append('logo', image);
    formData.append('address', address);
    formData.append('city', city);
    formData.append('province', province);
    formData.append('country', country);
    formData.append('website', website);
    formData.append('bookingLink', bookingLink);
    formData.append('reactivationDuration', duration);
    formData.append('reactivationMessage', message);

    const response = await api.request({
      url: `/reactivation/createBusiness`,
      method: "POST",
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

    // returning the product returned by the API
    return response
  },

  //Get Clients
  getClients: async function (userId) {

    const response = await api.request({
      url: `/reactivation/getClients`,
      method: "GET",
      params: {
        userId: userId,
      },
    })
    
    // returning the product returned by the API
    return response
  },

  //Upload Clients
  uploadClients: async function (userID, file) {

    const formData = new FormData();
    formData.append('userID', userID);
    formData.append('clients', file);

    const response = await api.request({
      url: `/reactivation/uploadClients`,
      method: "POST",
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    
    // returning the product returned by the API
    return response
  },

  //Run Reactivation
  runReactivation: async function (userId) {

    const response = await api.request({
      url: `/reactivation/checkReactivation`,
      method: "POST",
      data: {
        userId: userId,
      },
    })
    
    // returning the product returned by the API
    return response
  },

  //Save SMTP Settings
  saveSMTP: async function(userId, smtp, port, username, password) {

    const response = await api.request({
      url: `/reactivation/SMTPsetup`,
      method: "POST",
      data: {
        userId: userId,
        host: smtp,
        port: port,
        username: username,
        password: password,
      },
    })
    
    // returning the product returned by the API
    return response
  }

}

