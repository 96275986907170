import * as React from 'react';
import { useState, useEffect } from "react";
import Swal from 'sweetalert2';

import TextField from '@mui/material/TextField';

//Is Mobile
import { isMobile } from 'react-device-detect';

//Navigate
import { useNavigate } from 'react-router-dom';

//Network
import { crmAPI } from '../../../api/crmAPI';

function EmailSetup(props) {

    const [smtp, setSMTP] = React.useState('');
    const [port, setPort] = React.useState('');
    const [username, setUsername] = React.useState([]);
    const [password, setPassword] = React.useState([]);

    const navigate = useNavigate();


    //When the user clicks on the Login button
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (smtp === '') {
            Swal.fire({
                icon: 'error',
                title: 'SMTP error',
                text: 'SMTP field is empty, please enter your SMTP Server',
            });
            return;
        }
        else if (port === '') {
            Swal.fire({
                icon: 'error',
                title: 'Port error',
                text: 'Port field is empty, please enter your email server port',
            });
            return;
        }
        else if (username === '') {
            Swal.fire({
                icon: 'error',
                title: 'Username error',
                text: 'Username field is empty, please enter your email server username',
            });
            return;
        }
        else if (password === '') {
            Swal.fire({
                icon: 'error',
                title: 'Password error',
                text: 'Password field is empty, please enter your email account password',
            });
            return;
        }
        else {
            const response = await crmAPI.saveSMTP(props.userId, smtp, port, username, password);

            //Check the response
            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Server Setting Save',
                    text: 'Server settings successfully saved',
                }).then((result) => {
                    if (result.isConfirmed) {

                        //refresh the page
                        window.location.reload();
                    }
                });
            }
        }
    };

    //Render
    return (
        <div className='dashboard-SMTP'>
            <div className='dashboard-title'>
                SMTP Server Settings
            </div> 
            <div className='dashboard-panels'>
                <TextField
                    id="smtp"
                    label="SMTP Server"
                    className='reporting_input'
                    value={smtp}
                    onChange={(e)=> setSMTP(e.target.value)}
                    sx={{ 
                        input: { color: 'white' },
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                        },
                        '& .MuiInputLabel-root': {
                            color: 'white',
                            fontSize: isMobile ? '0.8rem' : '1rem',
                        },
                    }}
                />
                <TextField
                    id="port"
                    label="Set Port"
                    className='reporting_input'
                    value={port}
                    onChange={(e)=> setPort(e.target.value)}
                    sx={{ 
                        input: { color: 'white' },
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                        },
                        '& .MuiInputLabel-root': {
                            color: 'white',
                            fontSize: isMobile ? '0.8rem' : '1rem',
                        },
                    }}
                />
            </div>
            <div className='dashboard-panels'>
                <TextField
                    id="username"
                    label="Username"
                    className='reporting_input'
                    value={username}
                    onChange={(e)=> setUsername(e.target.value)}
                    sx={{ 
                        input: { color: 'white' },
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                        },
                        '& .MuiInputLabel-root': {
                            color: 'white',
                            fontSize: isMobile ? '0.8rem' : '1rem',
                        },
                    }}
                />
                <TextField
                    id="password"
                    label="Set Password"
                    className='reporting_input'
                    type="password"
                    value={password}
                    onChange={(e)=> setPassword(e.target.value)}
                    sx={{ 
                        input: { color: 'white' },
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                        },
                        '& .MuiInputLabel-root': {
                            color: 'white',
                            fontSize: isMobile ? '0.8rem' : '1rem',
                        },
                    }}
                />
            </div>
            <div className='SMTPButtonDiv'>
                <button className='button' onClick={handleSubmit}>Save Settings</button>
            </div>
        </div>
    );
}

export default EmailSetup;
