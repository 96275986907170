import * as React from 'react';
import { useState, useEffect } from "react";

import Swal from 'sweetalert2';

import '../../css/loginStyle.css';

//Images
import Logo from '../../assets/images/crm.jpg';
import SideImage from '../../assets/images/002.jpg';

//Google Login
import {LoginSocialGoogle, IResolveParams} from 'reactjs-social-login';
import {GoogleLoginButton} from 'react-social-login-buttons';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { registerUser, loginUser, tokenLoginUser } from '../../redux/userActions'; // Import actions

//Onboarding
import CreateCompany from '../adminComponent/onboardComponent';

//Main Component
import Main from '../userComponent/businessComponent/mainComponent';

//Mobile?
import {isMobile} from 'react-device-detect';

//MUI
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

function Register() {

    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');

    //Google Signin responses
    const [name, setName] = React.useState('');
    const [image, setImage] = React.useState('');

    const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);

    //Redux Object
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);

    //Persistent Login
    useEffect(() => {

        const token = localStorage.getItem('RAToken');
        if (token) {
            
            //Check the token
            dispatch(tokenLoginUser(token));
        }
    }, [currentUser]);

    //Register the user
    const handleRegistration = (email, password) => {

        console.log("In here");

        //Register the user
        dispatch(registerUser(email, password));

    };

    //When the user clicks on the Login button
    const handleSubmit = (e) => {
        e.preventDefault();
        
        if (email === '') {
            Swal.fire({
                icon: 'error',
                title: 'Email error',
                text: 'Email field is empty, please enter your email',
            });
            return;
        }
        else if (password === '') {
            Swal.fire({
                icon: 'error',
                title: 'Password error',
                text: 'Password field is empty, please enter your password',
            });
            return;
        }
        else if (!privacyPolicyAccepted) {
            Swal.fire({
                icon: 'error',
                title: 'Privacy Policy error',
                text: 'Please accept the Privacy Policy',
            });
            return;
        }
        else {
            handleRegistration(email, password);
        }
    };

    if (!currentUser) {

        if (isMobile) {

            //Render the Login page
            return (
                <div className="containerMobileRegister">
                    <div className="right-column">
                        <img className='logo' src={Logo} />
                        <div className='right-column-box'>
                            <div className='titleDiv'>
                                <span>Welcome to ReActivation CRM! 👋🏻</span>
                            </div>
                            <hr />
                            <div className='subtitleDiv'>
                                <span>Sign-up here</span>
                            </div>
                            <LoginSocialGoogle
                                client_id={'495199920966-a41370dnlp37m685k4e1rvqsppvgn7it.apps.googleusercontent.com'}
                                onResolve={({ provider, data }) => {

                                    //Register the user
                                    handleRegistration(data.email, '');
                                }}
                                onReject={err => {
                                    console.log("Error");
                                    console.log(err);
                                }}
                                scope="profile email"
                            >
                                <GoogleLoginButton className='googleButton'/>
                            </LoginSocialGoogle>
                            <div className='subtitleDiv'>
                                <span>Or</span>
                            </div>
                            <div className='inputBox'>
                                <TextField
                                    id="email"
                                    label="Email"
                                    color='secondary'
                                    className='input'
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className='inputBox'>
                                <TextField
                                    id="password"
                                    label="Password"
                                    type="password"
                                    color='secondary'
                                    className='input'
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div className="linksPanel">
                                <div className='forgotPasswordBox'>
                                    <span><a href="/forgotPassword">Forgot Password?</a></span>
                                </div>
                                <div className='forgotPasswordBox'>
                                    <span><a href="/login">Login Here</a></span>
                                </div>
                            </div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="secondary"
                                        onChange={(e) => setPrivacyPolicyAccepted(e.target.checked)}
                                    />
                                }
                                label={<span>I accept the <a href="/privacy-policy">Privacy Policy</a></span>}
                            />
                            <div className='buttonDiv'>
                                <button className='button' onClick={handleSubmit}>Register</button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        else {

            //Render the Login page
            return (
                <div className="container">
                    <div className="left-column">
                        <img className='image' src={SideImage} />
                    </div>
                    <div className="right-column">
                        <img className='logo' src={Logo} />
                        <div className='right-column-box'>
                            <div className='titleDiv'>
                                <span>Welcome to ReActivation CRM! 👋🏻</span>
                            </div>
                            <hr />
                            <div className='subtitleDiv'>
                                <span>Sign-up here</span>
                            </div>
                            <LoginSocialGoogle
                                client_id={'495199920966-a41370dnlp37m685k4e1rvqsppvgn7it.apps.googleusercontent.com'}
                                onResolve={({ provider, data }) => {

                                    console.log(data);

                                    //Register the user
                                    handleRegistration(data.email, '');
                                }}
                                onReject={err => {
                                    console.log("Error");
                                    console.log(err);
                                }}
                                scope="profile email"

                            >
                                <GoogleLoginButton className='googleButton'/>
                            </LoginSocialGoogle>
                            <div className='subtitleDiv'>
                                <span>Or</span>
                            </div>
                            <div className='inputBox'>
                                <TextField
                                    id="email"
                                    label="Email"
                                    color='secondary'
                                    className='input'
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className='inputBox'>
                                <TextField
                                    id="password"
                                    label="Password"
                                    type="password"
                                    color='secondary'
                                    className='input'
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="secondary"
                                            onChange={(e) => setPrivacyPolicyAccepted(e.target.checked)}
                                        />
                                    }
                                    label={<span>I accept the <a href="https://invest.co-owners.ca/terms_of_service.html">Terms of Service</a></span>}
                                />
                            </div>
                            <div className="linksPanel">
                                <div className='forgotPasswordBox'>
                                    <span><a href="/forgotPassword">Forgot Password?</a></span>
                                </div>
                                <div className='forgotPasswordBox'>
                                    <span><a href="/login">Login Here</a></span>
                                </div>
                            </div>
                            
                            <div className='buttonDiv'>
                                <button className='button' onClick={handleSubmit}>Register</button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
    else {
        //Render the Dashboard page
        return (
            <div>
                <CreateCompany />
            </div>
        );
    }
}

export default Register;