import './App.css';

//Login 
import Login from './components/loginComponent/loginComponent';

//Register
import Register from './components/registerComponent/registerComponent';

//Forgot Password
import ForgotPassword from './components/loginComponent/forgotPasswordComponent';

//Forgot Password
import ResetPassword from './components/loginComponent/resetPasswordComponent';

//Create Company
import CreateCompany from './components/adminComponent/onboardComponent';

//Main
import Main from './components/userComponent/businessComponent/mainComponent';

//Routing for Reactjst
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useParams } from 'react-router-dom';

//Redux
import { Provider } from 'react-redux';
import store from './redux/store'; // Import your store

const ResetPasswordRoute = () => {
  const { token } = useParams(); // Get the token parameter from the URL

  // You can pass the token to your ResetPassword component or perform any other logic here
  return <ResetPassword token={token} />;
};


const App = () => {

    return (
        <>
          <Provider store={store}>
            <Router>
              <Routes>
                <Route path="/" element={<Register />} />
                <Route path="/login" element={<Login />} />
                <Route path="/forgotPassword" element={<ForgotPassword />} />
                <Route path="/reset-password/:token" element={<ResetPasswordRoute />} />
                <Route path="/main" element={<Main />} />
                
                {/** Admin Routes **/}
                <Route path="/createCompany" element={<CreateCompany />} />
    
                <Route path="*" element={<Register />} />
              </Routes>
            </Router>
          </Provider>
        </>
    );
}

export default App;

