import * as React from 'react';
import { useState, useRef, useEffect } from "react";
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';

//Images
import Background from '../../assets/images/admin_bg.png';
import UploadProfile from '../../assets/images/profileUpload.png';

//Styling
import '../../css/onBoard.css'

//Redux
import { useDispatch, useSelector } from 'react-redux';

//Navigate
import { useNavigate } from 'react-router-dom';

//SweetAlert
import Swal from 'sweetalert2';

//API
import { crmAPI } from '../../api/crmAPI';

const KeyCodes = {
    comma: 188,
    enter: 13
};

function CreateCompany() {

    //Variables
    const [partners, setPartners] = React.useState([]);

    const [name, setName] = React.useState('');
    const [address, setAddress] = React.useState('');

    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');

    const [website, setWebsite] = React.useState('');
    const [bookinglink, setBooking] = React.useState('');
    const [duration, setDuration] = React.useState('');
    const [message, setMessage] = React.useState('');


    const [ownerEmail, setOwnerEmail] = React.useState('');
    const [partnerId, setPartnerId] = React.useState(''); 

    //Loading after button pressed
    const [loading, setLoading] = useState(false);

    //Tags
    const [tags, setTags] = React.useState([{ id: 'Burger_Joint', text: 'Burger Joint' }]);
    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    //Profile pic
    const [image, setImage] = React.useState('');
    const [preview, setPreview] = useState(null);

    //Redux Object
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);

    //Google Places API
    const autoCompleteRef = useRef();
    const inputRef = useRef();

    //Navigate
    const navigate = useNavigate();

    const options = {
        types: ['geocode'],
        componentRestrictions: { country: ["ca","us"] }
    };

    const pageStyle = {
        //backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };

    useEffect(() => {

        if (!window.google) {
            console.error("Google Maps JavaScript API not loaded");
            return;
        }

        autoCompleteRef.current = new window.google.maps.places.Autocomplete(document.getElementById('business_address'), options);
        autoCompleteRef.current.addListener('place_changed', handlePlaceSelect);
        
    }, []);

    //Upload to server
    const createBusiness = async () => {
        
        try {

            const response = await crmAPI.createBusiness(
                currentUser.id,
                name,
                image,
                address,
                city,
                state,
                country, 
                website,
                bookinglink,
                duration,
                message
            );

            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Business Created',
                    text: 'Your business has been created successfully',
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/main');
                    }
                });
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handlePlaceSelect = () => {
        const addressObject = autoCompleteRef.current.getPlace();
        const address = addressObject.address_components;

        if (address) {

            //Set the address
            setAddress(addressObject.formatted_address);

            //Get the city and state
            for (let i = 0; i < address.length; i++) {
                if (address[i].types.includes('locality')) {
                    setCity(address[i].long_name);
                }
                if (address[i].types.includes('administrative_area_level_1')) {
                    setState(address[i].long_name);
                }
                if (address[i].types.includes('country')) {
                    setCountry(address[i].long_name);
                }
            }
        }
    }

    //Handle the image change
    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
        setPreview(URL.createObjectURL(e.target.files[0]));
    };


    //When the user clicks on the Submit button
    const handleSubmit = (e) => {
        e.preventDefault();
        
        //Make sure home address is filled in
        if (autoCompleteRef.current.getPlace() === undefined) {

            Swal.fire({
                icon: 'error',
                title: 'Address error',
                text: 'Please enter your home address to match with nearby businesses',
            });
        }

        //If Name is not present on user, then it needs to be filled in
        else if (name.length < 1) {

            Swal.fire({
                icon: 'error',
                title: 'Name error',
                text: 'Please enter your name',
            });
        }

        //If Business logo is not present on user, then it needs to be filled in
        else if (image.length < 1) {

            Swal.fire({
                icon: 'error',
                title: 'Profile image error',
                text: 'Please upload a profile image, this can be updated.',
            });
        }

        //If the duration is not present on user, then it needs to be filled in
        else if (duration.length < 1) {

            Swal.fire({
                icon: 'error',
                title: 'Owner Name error',
                text: 'Please enter the owner name',
            });
        }

        else {
          
            //Set Loading
            setLoading(true);
            
            //Create the Business
            createBusiness();
        }

    };

    if ((currentUser !== null) && (currentUser.business_id !== undefined)) {
        
        console.log(currentUser);
        //Redirect to the main page
        navigate('/main');
    }
    else {
    
        return (
            <div style={pageStyle}>
                <div className='formPanel' style={{height: '100%'}}>
                    <div className='title'>Add a Business</div>
                    <div className='subTitle'>Create a link a business with a user account</div>

                    {/* Get Business Logo */}
                    <div className='inputBox'>
                        <input
                            type="file"
                            id="logo"
                            accept="image/*"
                            onChange={handleImageChange}
                            style={{display: 'none'}} // Hide the default file input
                        />
                    
                        {preview ? 
                                <label htmlFor="logo" style={{cursor: 'pointer'}}>
                                    <div className='profileImagePanel'>
                                        <img src={preview} alt="preview" className="profileImage" />
                                        <span className="profileText">Upload Business Logo</span>
                                    </div>
                                </label>
                            :   <label htmlFor="logo" style={{cursor: 'pointer'}}>
                                    <div className='profileImagePanel'>
                                        <img className="profileImage" src={UploadProfile} /> 
                                        <span className="profileText">Upload the business logo</span>
                                    </div>
                                </label>
                        }

                    </div>

                    {/* Business Name */}
                    <div className='inputBox'>
                        <TextField
                            id="name"
                            label="Business Name"
                            color='secondary'
                            className='input'
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                        
                    <div className='inputBox'>
                        <TextField
                            id="business_address"
                            label="Set Business Address"
                            color='secondary'
                            className='input'
                            inputRef={inputRef}
                        />
                    </div>

                    {/* Business Website */}
                    <div className='inputBox'>
                        <TextField
                            id="website"
                            label="Website"
                            color='secondary'
                            className='input'
                            onChange={(e) => setWebsite(e.target.value)}
                        />
                    </div>

                    {/* Booking Link  */}
                    <div className='inputBox'>
                        <TextField
                            id="booking"
                            label="Set Appointment Link (ex. Calendly)"
                            color='secondary'
                            className='input'
                            onChange={(e) => setBooking(e.target.value)}
                        />
                    </div>

                    {/* ReActivation duration  */}
                    <div className='inputBox'>
                        <TextField
                            id="duration"
                            label="Duration Before ReActivation Email"
                            color='secondary'
                            className='input'
                            onChange={(e) => setDuration(e.target.value)}
                        />
                    </div>

                    {/* Booking Link  */}
                    <div className='inputBox'>
                        <TextField
                            id="message"
                            label="ReActivation Message"
                            color='secondary'
                            className='input'
                            onChange={(e) => setMessage(e.target.value)}
                        />
                    </div>

                    <div className='buttonDiv'>
                        <button className='button' onClick={handleSubmit}>Register</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default CreateCompany;
