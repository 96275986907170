import { DataGrid, GridPagination } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';

import '../../../css/dashBoard.css'

//isMobile
import { isMobile } from 'react-device-detect';
import { getValueFromValueOptions } from '@mui/x-data-grid/components/panel/filterPanel/filterPanelUtils';


function ClientTable (props) {

    const navigate = useNavigate();

    const clients = props.clients;

    let columns;

    if (isMobile) {
        
        columns = [
            { field: 'name', headerName: 'Business Name' },
            { field: 'service', headerName: 'Last Service' },
            { 
                field: 'date', 
                headerName: 'Last Date',
                renderCell: (params) => (
                    <strong style={{ color: 'red' }}>
                      {params.value}
                    </strong>
                ),
            }
        ];
    }
    else {

        columns = [
            { field: 'name', headerName: 'Client Name', flex: 1 },
            { field: 'service', headerName: 'Last Service', flex: 1.5 },
            { field: 'date', headerName: 'Last Date', flex: 1, 
              renderCell: (params) => (
                <strong style={{ color: 'red' }}>
                  {params.value}
                </strong>
            ), }
        ];
    }
    
    const rows = clients.map((client) => {

        return {
            id: client.id,
            name: client.name,
            service: client.service,
            date: new Date(client.date).toLocaleDateString()
        }
    });
      
    return (
        <div className='clientTable'>
            <DataGrid 
                columns={columns} 
                rows={rows}
                pageSizeOptions={[10]}
                sx={{
                    border: 0,
                    color: 'black',
                    fontWeight: 'bold',
                    '&, [class^=MuiDataGrid]': { border: 'none' },
                }} 
                localeText={{noRowsLabel: 'No Clients Saved'}} // Change "No rows" to "No notifications"
            />
        </div>
    )
}

export default ClientTable;