import * as React from 'react';
import { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import Swal from 'sweetalert2';
import { crmAPI } from '../../api/crmAPI';

import '../../css/loginStyle.css';

//Images
import Logo from '../../assets/images/signin.jpg';

//Main Component
import Main from '../userComponent/businessComponent/mainComponent';

//Google Login
import {LoginSocialGoogle, IResolveParams} from 'reactjs-social-login';
import {GoogleLoginButton} from 'react-social-login-buttons';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, googleLoginUser, tokenLoginUser } from '../../redux/userActions'; // Import actions

//Main
//import Main from '../userComponent/businessComponent/mainComponent';

//Mobile?
import {isMobile} from 'react-device-detect';

function Login() {

    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');

    //Redux Object
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);

    //Persistent Login
    useEffect(() => {

        const token = localStorage.getItem('RAToken');
        if (token) {
            
            //Check the token
            dispatch(tokenLoginUser(token));
        }
    }, []);
    
    //When the user clicks on the Login button
    const handleSubmit = (e) => {
        e.preventDefault();
        
        if (email === '') {
            Swal.fire({
                icon: 'error',
                title: 'Email error',
                text: 'Email field is empty, please enter your email',
            });
            return;
        }
        else if (password === '') {
            Swal.fire({
                icon: 'error',
                title: 'Password error',
                text: 'Password field is empty, please enter your password',
            });
            return;
        }
        else {
            
            //Check user credentials for login
            dispatch(loginUser(email, password));
        }
    };

    if (!currentUser) {
        
        if (isMobile) {
            //Render the Login page
            return (
                <div className="containerMobileLogin">
                    <div className="right-column">
                        <div className='right-column-box'>
                            <div className='titleDiv'>
                                <span>Welcome to Allies! 👋🏻</span>
                            </div>
                            <hr/>
                            <LoginSocialGoogle
                                client_id={'495199920966-a41370dnlp37m685k4e1rvqsppvgn7it.apps.googleusercontent.com'}
                                onResolve={({ provider, data }) => {
                                    
                                    dispatch(googleLoginUser(data.email));
                                }}
                                onReject={err => {
                                    console.log(err);
                                }}
                            >
                                <GoogleLoginButton className='googleButton'/>
                            </LoginSocialGoogle>
                            <div className='subtitleDiv'>
                                <span>Or Sign-in below</span>
                            </div>
                            <div className='inputBox'>
                                <TextField
                                    id="email"
                                    label="Email"
                                    color='secondary'
                                    className='input'
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className='inputBox'>
                                <TextField
                                    id="password"
                                    label="Password"
                                    type="password"
                                    color='secondary'
                                    className='input'
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div className="linksPanel">
                                <div className='forgotPasswordBox'>
                                    <span><a href="/forgotPassword">Forgot Password?</a></span>
                                </div>
                                <div className='forgotPasswordBox'>
                                    <span><a href="/">Register Here</a></span>
                                </div>
                            </div>
                            <div className='buttonDiv'>
                                <button className='button' onClick={handleSubmit}>Sign In</button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        else {
            //Render the Login page
            return (
                <div className="container">
                    <div className="left-column">
                        <img className='image' src={Logo} />
                    </div>
                    <div className="right-column">
                        
                        <div className='right-column-box'>

                            <div className='titleDiv'>
                                <span>Welcome to your Reactivation Tool! 👋🏻</span>
                            </div>
                            <hr/>
                            <LoginSocialGoogle
                                client_id={'495199920966-a41370dnlp37m685k4e1rvqsppvgn7it.apps.googleusercontent.com'}
                                onResolve={({ provider, data }) => {
                                    
                                    dispatch(googleLoginUser(data.email));
                                }}
                                onReject={err => {
                                    console.log(err);
                                }}
                            >
                                <GoogleLoginButton className='googleButton'/>
                            </LoginSocialGoogle>
                            <div className='subtitleDiv'>
                                <span>Or Sign-in below</span>
                            </div>
                            <div className='inputBox'>
                                <TextField
                                    id="email"
                                    label="Email"
                                    color='secondary'
                                    className='input'
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className='inputBox'>
                                <TextField
                                    id="password"
                                    label="Password"
                                    type="password"
                                    color='secondary'
                                    className='input'
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div className="linksPanel">
                                <div className='forgotPasswordBox'>
                                    <span><a href="/forgotPassword">Forgot Password?</a></span>
                                </div>
                                <div className='forgotPasswordBox'>
                                    <span><a href="/">Register Here</a></span>
                                </div>
                            </div>
                            <div className='buttonDiv'>
                                <button className='button' onClick={handleSubmit}>SIGN IN</button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
    else {
        //Render the Dashboard page
        return (
            <div>
                <Main />
            </div>
        );
    }
}

export default Login;