import * as React from 'react';
import { useState, useEffect, useRef } from "react";

import '../../../css/businessMarket.css';
import '../../../css/crm.css';

//Network
import { crmAPI } from '../../../api/crmAPI';

//Navigate
import { useNavigate } from 'react-router-dom';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { tokenLoginUser } from '../../../redux/userActions'; // Import actions

//Menu Component
import Loading from '../loadingComponent/loadingComponent'

//Images
import UploadProfile from '../../../assets/images/profileUpload.png';

//Clients
import ClientTable from './clientTableComponent';

//Email Setup
import EmailSetup from './emailSetupComponent';

//Partnership Leaderboard
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField';

//SweelAlert
import Swal from 'sweetalert2';

function Main() {

    //Redux Object
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);

    const navigate = useNavigate();

    //Clients
    const [clients, setClients] = useState([]);

    useEffect(() => {

        const getUser = async () => {
            const token = localStorage.getItem('RAToken');
            if (token) {
                
                //Check the token
                dispatch(tokenLoginUser(token));
            }
            else {
                navigate('/login');
            }
        }

        //If the user is not loaded, load the user
        if (currentUser === null) {
        
            const fetchUser = async () => {
                await getUser();
            };

            fetchUser();
        } else {
        
            //Get the User Clients
            const getClients = async () => {
                const response = await crmAPI.getClients(currentUser.id);
                setClients(response.data);
            }

            getClients();
        } 


    }, [currentUser]);

    //Handle the CSV File change
    const handleCSVChange = (e) => {
        e.preventDefault();

        const file = e.target.files[0];

        if (file) {
            
            //Check the file type to make sure it is a CSV file
            if (file.type === 'text/csv') {
                const reader = new FileReader();
                reader.onload = async (e) => {
                    const text = (e.target.result);
                    const lines = text.split('\n');
                    const headers = lines[0].split(',');

                    //Check to see if the headers are correct
                    if (headers[0] === 'Name' && headers[1] === 'Email' && headers[2] === 'Service' && headers[3] === 'Last Date\r') {
                        
                        //Send the data to the server
                        const response = await crmAPI.uploadClients(currentUser.id, file);

                        //Check the response
                        if (response.status === 200) {
                            Swal.fire({
                                icon: 'success',
                                title: 'CSV File Imported',
                                text: 'The CSV file has been imported successfully',
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    //refresh the page
                                    window.location.reload();
                                }
                            });
                        }
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: 'CSV File Error',
                            text: 'The CSV file is not formatted correctly',
                        });
                    }
                };
                reader.readAsText(file);
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'CSV File Error',
                    text: 'The file is not a CSV file',
                });
            }
        }
    };

    /** 
     * Create a new Company
     */
    const createCompanyButtonPanel = () => {

        return (
            <div className='importPanel'>
                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                    <Button onClick={(e) => navigate('/createCompany')}>Create Company</Button>
                </ButtonGroup>
            </div>
        );
    }

    /**
     * Panel where there is a button to import a CSV file
    */
    const importButtonPanel = () => {

        return (
            <div className='inputBox'>
                <input
                    type="file"
                    id="csvFile"
                    accept=".csv"
                    onChange={handleCSVChange}
                    style={{display: 'none'}} // Hide the default file input
                />
                <label htmlFor="csvFile" style={{cursor: 'pointer'}}>
                    <div className='importPanel'>
                        <span className="importButton">Upload Client CSV File</span>
                    </div>
                </label>
            </div>
        );
    }

    /**
     * Show the Clients in Table format
     */
    const showClients = () => {            

        if (clients.length > 0) {
            return (
                <ClientTable clients={clients} />
            );
        }
        else {
            return (
                <div className='importPanel'>
                    No Clients Imported
                </div>
            )
        }

    }

    /**
     * Panel where there is a button to import a CSV file
    */
     const sendReactivationEmails = () => {

        return (
            <div className='buttonPanel'>
                <Button className="button" onClick={submitReActivation}>Send ReActivations Emails</Button>
            </div>
        );
    }

    /**
     * Email Server settings page
     */
    const emailSettings = () => {
        
        if (currentUser.business_email === null) {
            return (
                <EmailSetup userId={currentUser.id}/>
            );
        }
    }

    /**
     * Submit the Reactivation Emails
     * @param {*} e
     * @returns
     */
    const submitReActivation = async (e) => {

        e.preventDefault();

        if (currentUser.business_email === null) {
            Swal.fire({
                icon: 'error',
                title: 'Business Email Error',
                text: 'Business Email server is not setup yet, please setup your business SMTP server settings first',
            });
            return;
        }
        else {
            const response = await crmAPI.runReactivation(currentUser.id);

            if (response.status === 200) {

                Swal.fire({
                    icon: 'success',
                    title: 'Reactivation Emails Sent',
                    text: 'The reactivation emails have been sent successfully',
                });
            }
        }
    }


    /*** Main ****/
    //Navigation Title Panel
    const panelTitle = () => {
    
        return (
            <div className="dashboard-titlePanel" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', alignItems: 'center' }}>
                <div></div>
                <span className="dashboard-title" style={{ textAlign: 'center' }}>
                    { currentUser.business_name }'s Dashboard
                </span>
                <div style={{ textAlign: 'right' }}>
                    { currentUser.business_logo !== null ? 
                        <img src={ currentUser.business_logo } alt="Logo" className="header_logo" />
                    : null }
                </div>
            </div>
        );
    }

    function Dashboard() {

        return (
            <div className="dashboard">
                <div className="dashboard-content">
                    { panelTitle() }
                    {/*{ currentUser !== null && currentUser.role === 'admin' && createCompanyButtonPanel()}*/}
                    { importButtonPanel() }
                    { showClients() }
                    { emailSettings() }
                    { sendReactivationEmails() }
                </div>
            </div>
        );
    }

    if (currentUser !== null) {

        return (
            <div>
                <Dashboard />
            </div>
        );
    }
    else {
        return (
            <Loading content="Loading User"/>
        );
    }
}

export default Main;

